<template>
  <div class="admin-about__info info">
    <router-link to="/admin/about" class="info__title">Документы о приложении</router-link>
    <div class="info__content">
      <div class="info__content-header">
        <img src="@/assets/svg/icon-docs-red.svg" alt="" class="image"/>
        <p>Описание приложения</p>
        <img class="info__content-header-edit" src="@/assets/svg/admin/edit-default.svg" alt=""/>
        <img class="info__content-header-delete" src="@/assets/svg/icon-delete-admin.svg" alt=""/>
      </div>
      <p class="info__content-text">Это глобальное сообщество людей, которые стремятся сохранить планету чистой и внести свой вклад в борьбу с изменением климата, “Climate Security” показывает всем неравнодушным людям реальную экологическую карту мира.

        Фонд климатической безопасности открывает каждому пользователю Приложения возможность заявить об экологических правонарушениях в любой точке земного шара (“Красная кнопка”).

        После подачи обращения от пользователя партнёры Фонда в разных странах проверяют достоверность информации, действуя в правовом поле и исходя из специфики законодательства того или иного государства.

        Главная цель-устранить нарушение и привлечь виновных к ответственности.

        Пользователям гарантируется конфиденциальность-после создания обращения всю дальнейшую переписку Фонда его иностранные партнёры ведут от своего имени, не раскрывая личности Пользователя приложения.

        Приложение позволяет также зафиксировать положительные климатические инициативы, открывает для пользований возможности отслеживать динамику реализации таких проектов (“Зелёная кнопка”).

        На интерактивной карте мира отображается реальная картина в разрезе стран, республик, областей и формируется рейтинг экологической безопасности регионов.

        “Climate Security” поощрит самых активных пользователей.

        Приложение формирует рейтинг, на основании которого Фонд климатической безопасности периодически будет награждать лучших активистов.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutId"
}
</script>

<style lang="scss" scoped>
.info {
  display: flex;
  flex-direction: column;
  gap: 56px;
  padding: 150px 163px 56px 50px;
  min-height: 100vh;
  height: 100%;
  background: #F5F5F5;

  @media(max-width: 900px) {
    padding: 150px 50px 56px 50px;
    gap: 30px;
  }

  @media(max-width: 768px) {
    padding: 104px 20px 56px 20px;
  }

  &__title {
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #343432;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 42px 40px 46px 40px;
    background: #FFFFFF;
    width: 100%;
    border-radius: 20px;

    @media (max-width: 700px) {
      padding: 30px 12px;
    }

    &-header {
      display: flex;
      align-items: center;
      gap: 20px;

      &-edit {
        width: 16px;
        height: 16px;
      }

      &-delete {
        width: 24px;
        height: 24px;
      }
    }

    &-text{
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: #1B1A1F;
    }
  }
}
</style>